@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');

*{
    font-family: "Quicksand", sans-serif;
}

.banner-set{
    background-image: url(../public/image/banner.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.mycolor{
    color:rgb(164, 164, 164);
}
.mycolor h1{
    color: white;
    text-decoration: underline;
}
.newset{
    background-image: url("../public/image/bigset.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}